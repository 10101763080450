import authApi from '~/composables/api/authApi'
import { keyLocalStorage } from '~/enum/enquiry'

const actions = {
    async login(context, request) {
        const response = await authApi.login(request)
        context.commit('setLogin', response.data)

        return response.data
    },

    async signUp(context, request) {
        const response = await authApi.signUp(request)
        context.commit('setSignUp', response.data)

        return response.data
    },

    async logout(context) {
        const response = await authApi.logout()
        context.commit('setLogout', response.data)
        sessionStorage.removeItem(keyLocalStorage.enquiry)

        return response.data
    },

    async verify(context) {
        const response = await authApi.verify()
        context.commit('setVerify', response?.data?.value || response?.error?.value?.data)
    },

    async signUpAgent(context, request) {
        const response = await authApi.signUpAgent(request)
        context.commit('setSignUpAgent', response.data)

        return response.data
    },
    
    async signUpAgentPrivate(context, request) {
        const response = await authApi.signUpAgentPrivate(request)
        context.commit('setSignUpAgent', response.data)
        return response.data
    },

    async forgotPassword(context, request) {
        const response = await authApi.forgotPassword(request)
        context.commit('setForgotPassword', response.data)

        return response.data
    },

    async checkCompany(context, request) {
        const response = await authApi.checkCompany(request)
        context.commit('setCompany', response.data)

        return response.data
    },

    async verifyEmail(context, request) {
        const response = await authApi.verifyEmail(request)
        context.commit('setVerifyEmail', response.data)

        return response.data
    }
}

const getters = {
    getUserInfo: (state) => state.auth,
}

const mutations = {
    setLogin: (state, response) => {
        state.auth.hash = response && response.data ? response.data.hash : null
        state.auth.userType = response && response.data ? response.data.user_type : null
        state.auth.error = response.error
        state.auth.message = response.message
        state.auth.userId = response && response.data ? response.data.user_id : null
        state.auth.user_id = response && response.data ? response.data.user_id : null
    },

    setSignUp: (state, response) => {
        state.auth.hash = response && response.data ? response.data.hash : null
        state.auth.userType = response && response.data ? response.data.user_type : null
        state.auth.error = response.error
        state.auth.message = response.message
        state.auth.userId = response && response.data ? response.data.user_id : null
        state.auth.user_id = response && response.data ? response.data.user_id : null
    },

    setLogout: (state, response) => {
        state.auth.error = response.error
        state.auth.message = response.message
    },

    setVerify: (state, response) => {
        state.auth.avatar = response?.data?.avatar || null
        state.auth.hash = response?.data?.hash || null
        state.auth.token = response?.data?.token || null
        state.auth.userType = response?.data?.user_type || null
        state.auth.timezone = response?.data?.timezone || ''
        state.auth.userId = response?.data?.user_id || null
        state.auth.user_id = response?.data?.user_id || null
        state.auth.firstName = response?.data?.first_name || ''
        state.auth.lastName = response?.data?.last_name || ''
        state.auth.email = response?.data?.email || ''
        state.auth.error = response?.error || 0
        state.auth.message = response?.message || ''
        state.auth.status = response?.status || null
        state.auth.phone = response?.data?.phone || ''
        state.auth.countryCode = response?.data?.country_code || ''
        state.auth.currencyCode = response?.data?.currency_code || ''
    },

    setSignUpAgent: (state, response) => {
        state.auth.hash = response && response.data ? response.data.hash : null
        state.auth.userType = response && response.data ? response.data.user_type : null
        state.auth.error = response.error
        state.auth.message = response.message
    },

    setForgotPassword: (state, response) => {
        state.auth.error = response.error
        state.auth.message = response.message
    },

    setCompany: (state, response) => {
        state.auth.company_id = response.data.company_id
    },

    setVerifyEmail: (state, response) => {
        state.auth.error = response.error
        if(response.error === 0) {
            state.auth.is_valid = response.data.is_valid
            state.auth.is_customer = response.data.is_customer
        } else {
            state.auth.message = response.message
        }
    },

    setUserChatLogin: (state, response) => {
        state.auth = response
    }
}

const state = () => {
    return {
        auth:{
            avatar: null,
            hash: null,
            token: null,
            userType: null,
            userId: null,
            user_id: null,
            timezone: '',
            firstName: '',
            lastName: '',
            email: '',
            company_id: 0,
            error: 0,
            message: '',
            status: null,
            phone: '',
            countryCode: '',
            is_valid: 0,
            is_customer: 0,
            currencyCode: ''
        }
    }
}

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters
}
