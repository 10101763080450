import getFiltersApi from '~/composables/api/filtersApi'

const actions = {
    async popularDestination(context, request) {
        const response = (await getFiltersApi.popular(request)).data.value
        context.commit('setPopularDestination', response || [])
        return response || []
    },
    async getFilterOptions(context, request) {
        const response = (await getFiltersApi.getFilters(request)).data.value
        context.commit('setFiltersListHome', response)
        return response
    },
}

const mutations = {
    setPopularDestination: (state, response) => {
        state.popularList = response.data
    },

    setFiltersList: (state, response) => {
        /*if (response && response.durations) {
          const lastIndex = response.durations.length-1
          const lastRange = response.durations[lastIndex].range
          response.durations[lastIndex].range = lastRange.replace(/.$/,"-")
        }*/
        state.filtersData = response
    },

    setFiltersSelected: (state, data) => {
        state.filterSelected = data
    },

    setDestinations: (state, response) => {
        state.destinationsData = response
    },

    setGalleryInfor: (state, response) => {
        state.galleryInfor = response
    },

    setFiltersListHome: (state, response) => {
        const sortedActivity = response?.data?.activities.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1)
        state.filtersHome = {
            activities: sortedActivity,
            destinations: response?.data?.destinations,
            durations: response?.data?.durations,
            prices: response?.data?.prices
        }
    },

    setTripData(state, response) {
        state.tripData = response
    },

    setClearFilter(state, data) {
        state.clearFilter = data
    },

    setQuickLinks(state, data) {
        let list = {
            destinaions: data[0],
            activities: data[1],
            duration: data[2],
        }
        state.quickLinksData = list
    }
}

const state = () => {
    return {
        filtersData: {},
        destinationsData: {},
        localDesignersData: [],
        galleryInfor: {},
        popularList: [],
        filterSelected: {},
        filtersHome: {},
        tripData: {},
        clearFilter: false,
        quickLinksData: {}
    }
}

export default {
    namespaced: true,
    mutations,
    actions,
    state
}
