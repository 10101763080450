import searchApi from '~/composables/api/searchApi'
import localDesignerApi from '~/composables/api/localDesignerApi'
import { SEARCH_TAB } from '~/constant/common'
// import {parseJsonStream} from './helper';

const actions = {
    async setSuggestionsSearch(context) {
        context.commit('setSuggestionsSearchStatus', true)
        const res = (await searchApi.getSuggestionSearch()).data.value
        context.commit('setSuggestionsSearch', res?.data)
    },

    async setSuggestionByKeyword(context, keyword) {
        context.commit('setSuggestionKeywordStatus', true)
        const params = {
            q: keyword,
            limit: 10
        }
        const { data } = await searchApi.getSuggestionByKeyword(params)
        context.commit('setSuggestionKeyword', data.data)
    },


    async setSearchResult(context, paramsRoot) {
        // console.log('page ',context.state.infiniteScroll.page)
        if (context.state.infiniteScroll.isLoading) return

        if(context.state.infiniteScroll.page > context.state.infiniteScroll.totalPage) {
            context.commit('setInfiniteLoadingStatus', false)
            context.commit('setAPILoaded', true)
            return
        }

        context.commit('setInfiniteLoadingStatus', true)
        context.commit('setAPILoaded', false)

        const params = {
            q: paramsRoot?.keyword || undefined,
            type: paramsRoot?.type || undefined,
            page: context.state.infiniteScroll.page,
            limit: context.state.infiniteScroll.limit,
            // get_feature_trip: 1
            default: paramsRoot?.default || undefined,
            currency: paramsRoot?.currency || undefined
        }

        const { data } = await searchApi.getSearchResult(params)

        context.commit('setAPILoaded', true)
        context.commit('setSearchResult', data?.data)
        context.commit('setInfiniteLoadingTripPlans', data?.data)
    },

    // setGenerativeTextStatus(context, status) {
    //   context.commit('setGenerativeTextStatus', status)
    // },

    // async setGenerativeText(context, data) {
    //   context.commit('setGenerativeText', data)
    // },

    async setLocalDesigners(context) {
        context.commit('setLocalDesignersStatus', true)
        const params = {
            designers_id: context.state?.searchResult?.designers_id.join(','),
            limit: -1,
            destinations: context.state?.searchResult?.destinations.toString() || undefined,
        }
        const { data } = await searchApi.getDesignersSearch(params)
        context.commit('setLocalDesigners', data.data)
    },

    async setLDReviews(context) {
        const params = {
            country_ids: context.state?.searchResult?.country_codes.join(','),
            limit: 12
        }
        const { data } = await localDesignerApi.getTravellerReviews(params)
        context.commit('setLocalDesignerReviews', data.data)
    },

    // resetData(context) {
    //   context.commit('resetData')
    // },

    async getTravelGuideTabData({state, commit}){
        // const oldData = state.dataTravelGuidesBlog
        const params = {
            // country_codes: Object.values(state?.searchResult?.country_codes).toString() || state?.searchResult?.country_codes.toString() || undefined,
            country_codes: state?.searchResult?.country_codes.toString() || undefined,
            destinations: state?.searchResult?.destinations.toString() || undefined,
            activities: state?.searchResult?.activities.toString() || undefined,
            // limit: 8
        }

        const resp = await searchApi.getTravelGuidesAndBlogs(params)
        // const apiReturn = {
        // travel_guides: resp?.data?.data?.travel_guides,
        // blogs: resp?.data?.data?.blogs
        // blogs: oldData?.blogs ? [...oldData?.blogs, ...resp?.data?.data?.blogs] : resp?.data?.data?.blogs
        // }
        commit('setDataTravelGuidesBlog',resp?.data?.data)
        if(state?.searchResult?.trips) commit('setShowNoResult',false)
    }
}

const mutations = {
    setTripPlansResult(state, payload) {
        state.tripPlans = payload
    },
    setReviewsResult(state, payload) {
        state.reviews = payload
    },
    setLDsResult(state, payload) {
        state.localDesigners = payload
    },
    setBlogsResult(state, payload) {
        state.blogs = payload
    },
    setSuggestionsSearchStatus(state, payload) {
        state.suggestionsSearch.isLoading = payload
    },
    setSuggestionsSearch(state, payload) {
        // state.suggestionsSearch.keyword = payload.keyword
        // state.suggestionsSearch.activities = payload.activities
        // state.suggestionsSearch.popular_trips = payload.popular_trips
        state.suggestionsSearch.list = payload
        state.suggestionsSearch.isLoading = false
    },
    setSuggestionKeywordStatus(state, payload) {
        state.suggestionKeyword.isLoading = payload
    },
    setSuggestionKeyword(state, payload) {
        state.suggestionKeyword.activities = payload?.activities
        state.suggestionKeyword.destinations = payload?.destinations
        state.suggestionKeyword.keywords = payload?.keywords
        state.suggestionKeyword.isLoading = false
    },
    setSearchResult(state, payload) {
        state.searchResult = payload
    },
    setGenerativeTextStatus(state, payload) {
        state.generativeText.isLoading = payload
    },
    setGenerativeText(state, payload) {
        state.generativeText.data = payload
        state.generativeText.isLoading = false
    },
    setStreamGenerativeText(state, payload) {
        state.generativeText.data += payload
    },
    setInfiniteLoadingTripPlans(state, payload) {
        // const newTripPlans = objectsDifferentBetweenArrays(payload, state.infiniteScroll.tripPlans)
        if( state.infiniteScroll.page === 1)  state.infiniteScroll.totalTrip = payload?.total
        state.infiniteScroll.isLoading = false
        state.infiniteScroll.page++
        state.infiniteScroll.totalPage = Math.ceil(payload?.total / state.infiniteScroll.limit) || 1
        state.infiniteScroll.tripPlans = payload?.trips ? [...state.infiniteScroll.tripPlans, ...payload?.trips] : [...state.infiniteScroll.tripPlans]
    },
    setInfiniteLoadingStatus(state, payload) {
        if (payload) {
            state.infiniteScroll.isLoading = payload
            // state.infiniteScroll.page++
        }
    },
    setLocalDesignersStatus(state, payload) {
        state.searchLocalDesigners.isLoading = payload
    },
    setLocalDesigners(state, payload) {
        state.searchLocalDesigners.data = payload
        state.searchLocalDesigners.isLoading = false
    },
    setLocalDesignerReviews(state, payload) {
        state.searchLocalDesigners.reviews = payload
    },
    resetData(state) {
        const newState = {
            suggestionsSearch: {
                keyword: [],
                activities: [],
                popular_trips: [],
                isLoading: false,
                list: {}
            },
            suggestionKeyword: {
                activities: [],
                destinations: [],
                keywords: [],
                isLoading: false,
            },
            searchResult: {
            },
            infiniteScroll: {
                tripPlans: [],
                page: 1,
                isLoading: false,
                totalPage: 1,
                limit: 12,
                totalTrip: 0
            },
            generativeText: {
                data: '',
                isLoading: state.generativeText.isLoading,
            },
            // searchLocalDesigners: {
            //   data: [],
            //   reviews: [],
            //   isLoading: false,
            // },
            apiLoaded: true,
            tabSelected: SEARCH_TAB[0].id,
            // dataTravelGuidesBlog: {},
            // dataLocalDesigner: []
            featureTrip: null
        }
        // state.suggestionKeyword = newState.suggestionKeyword
        state.searchResult = newState.searchResult
        state.infiniteScroll = newState.infiniteScroll
        state.generativeText = newState.generativeText
        state.tabSelected = newState.tabSelected
        state.featureTrip = newState.featureTrip
        state.dataTravelGuidesBlog = {}
    },
    setAPILoaded(state, payload) {
        state.apiLoaded = payload
    },
    setTabActive(state, payload) {
        state.tabSelected = payload
    },
    setDataTravelGuidesBlog(state, payload) {
        state.dataTravelGuidesBlog = payload
    },
    setFeatureTrip(state, payload) {
        state.featureTrip = payload
    },
    setStreamDone(state, payload) {
        state.streamDone = payload
    },
    setTabVisible(state, payload) {
        state.tabVisible = payload
    },
    setShowNoResult(state, payload) {
        state.showNoResult = payload
    },
    setPlaceholderTextIndex(state, payload) {
        state.placeholderTextIndex = payload
    },
    setRecaptcha(state, payload) {
        state.recaptcha = payload
    }
}

const getters = {
    getLDById: (state) => (id) => state?.localDesigners?.find((ld) => ld.id === id),
    getLDs: (state) => state?.localDesigners,
    // getSearchResultPrimaryTrip: (state) => state.searchResult?.trips?.length ? state?.searchResult?.trips[0] : null,
    getSearchResultPrimaryTrip: (state) => state?.featureTrip || null,
    getSuggestionsSearch: (state) => state?.suggestionsSearch,
    getSuggestionSearchStatus: (state) => state?.suggestionsSearch?.isLoading,
    getSuggestionKeyword: (state) => {
        const newSuggestionByKeyword = {
            activities: state?.suggestionKeyword?.activities || [],
            destinations: state?.suggestionKeyword?.destinations || [],
            keywords: state?.suggestionKeyword?.keywords || [],
        }
        return newSuggestionByKeyword
    },
    getSuggestionKeywordStatus: (state) => state?.suggestionKeyword?.isLoading,
    getSearchResult: (state) => state?.searchResult,
    getGenerativeText: (state) => state?.generativeText?.data,
    getGenerativeTextStatus: (state) => state?.generativeText?.isLoading,
    getInfiniteLoadingTripPlans: (state) => state.infiniteScroll.tripPlans,
    // getInfiniteLoadingTripPlans: (state) => () => {
    // if (state?.infiniteScroll?.tripPlans?.length === state.searchResult?.total) return state?.infiniteScroll?.tripPlans
    // else {
    //   const cloneTripPlans = [...state.infiniteScroll.tripPlans]
    //   // const spliced = cloneTripPlans.splice(1, (cloneTripPlans.length - 1) - ((cloneTripPlans.length - 1) % length))
    //   const spliced = cloneTripPlans.splice(1, (cloneTripPlans.length - 1))
    //   return spliced
    // }
    // const cloneTripPlans = [...state.infiniteScroll.tripPlans]
    // const spliced = cloneTripPlans.splice(1, (cloneTripPlans.length - 1))
    // return state.infiniteScroll.tripPlans
    // },
    getInfiniteLoadingStatus: (state) => state?.infiniteScroll?.isLoading,
    shouldLoadMore: (state) => !(state?.infiniteScroll?.tripPlans?.length === state.searchResult?.total),
    getSearchLocalDesigners: (state) => state?.searchLocalDesigners?.data,
    getSearchLocalDesignersReviews: (state) => state?.searchLocalDesigners?.reviews,
    getSearchLocalDesignersStatus: (state) => state?.searchLocalDesigners?.isLoading,
}

const state = () => ({
    suggestionsSearch: {
        keyword: [],
        activities: [],
        popular_trips: [],
        isLoading: false,
        list: {}
    },
    suggestionKeyword: {
        activities: [],
        destinations: [],
        keywords: [],
        isLoading: false,
    },
    searchResult: {
    },
    infiniteScroll: {
        tripPlans: [],
        page: 1,
        isLoading: false,
        totalPage: 1,
        limit: 12,
        totalTrip: 0
    },
    generativeText: {
        data: '',
        isLoading: false,
    },
    searchLocalDesigners: {
        data: [],
        reviews: [],
        isLoading: false,
    },
    apiLoaded: true,
    tabSelected: SEARCH_TAB[0].id,
    dataTravelGuidesBlog: {},
    featureTrip: null,
    streamDone: true,
    tabVisible: SEARCH_TAB,
    showNoResult: false,
    placeholderTextIndex: 0,
    recaptcha: null
})

export default {
    namespaced: true,
    mutations,
    actions,
    getters,
    state,
}
