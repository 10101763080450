import { parsePhoneNumber } from 'libphonenumber-js'
import type { Commit } from 'vuex/types/index.js'
import enquiryTravellerApi from '~/composables/api/enquiryTravellerApi'
import { ENQUIRY_STEP_BASE, EnquiryStepEnum, keyLocalStorage } from '~/enum/enquiry'
import type { EnquiryMultiStepObj } from '~/models/EnquiryCapture/EnquiryMultiStepObj'
import countryList from '~/components/ui/country-selectbox/country.json'
import { getEnquiryID, storeStepData } from '~/helper/enquiry'

interface EnquiryCaptureState {
  submitFrom: string
  hasEnquiryCapture: boolean
  loading: boolean
  enquiryCaptureData: EnquiryMultiStepObj | null
  enquiryAuthenticate: any
  userLoginData: object
  enquiryOptionData: object
  recaptcha: any
  recaptcha_token: any
  galleryData: any
  stepsData: any
  arrayStep: EnquiryStepEnum[]
}

const mutations = {
  setHasEnquiryCapture: (state: EnquiryCaptureState, data: boolean) => {
    state.hasEnquiryCapture = data
  },
  setLoadingEnquiryCapture: (state: EnquiryCaptureState, data: boolean) => {
    state.loading = data
  },
  setEnquiryCaptureData: (state: EnquiryCaptureState, data: EnquiryMultiStepObj) => {
    state.enquiryCaptureData = data
  },
  setEnquiryAuthenticate: (state: EnquiryCaptureState, data: any) => {
    state.enquiryAuthenticate = data
  },
  setUserLoginData: (state: EnquiryCaptureState, data: EnquiryMultiStepObj) => {
    state.userLoginData = data
  },
  setEnquiryOptionData: (state: EnquiryCaptureState, data: any) => {
    state.enquiryOptionData = data
  },
  setRecaptcha: (state: EnquiryCaptureState, data: any) => {
    state.recaptcha = data
  },
  setRecaptchaToken: (state: EnquiryCaptureState, data: any) => {
    state.recaptcha_token = data
  },
  setGalleryData: (state: EnquiryCaptureState, payload: any) => {
    state.galleryData.data = payload?.data
    state.galleryData.listDestination = payload?.listDestination
  },
  setStepsData: (state: EnquiryCaptureState, data: object) => {
    state.stepsData = data
  },
  setStepInPath: (state: EnquiryCaptureState, payload: { step: EnquiryStepEnum; path: string }) => {
    if (state.stepsData && state.stepsData[payload.path]) {
      state.stepsData[payload.path].step = payload.step
    }
  },
  setArrayStep: (state: EnquiryCaptureState, data: EnquiryStepEnum[]) => {
    state.arrayStep = data
  },
}

const getters = {
  enquiryDataByStep: (state: EnquiryCaptureState) => (path: string, step: EnquiryStepEnum) => {
    if (state.stepsData && state.stepsData[path]) {
      return state.stepsData[path][step] || null
    }
    return null
  },
  enquiryId: (state: EnquiryCaptureState) => {
    if (!state.enquiryCaptureData) return null
    return state.enquiryCaptureData.enquiry_id
  },
}

const actions = {
  async getEnquiryByID({ commit }: { commit: Commit }, id: string) {
    // get data from API
    try {
      const { data: res } = await enquiryTravellerApi.getEnquiryByID(id)
      if (res.status === 200) {
        if (res.data && res.data.step === EnquiryStepEnum.YourBudget) {
          return { completed: true }
        }
        return mapDataEnquiryMultiStep(res.data)
      }
    } catch (error) {
      console.log('EnquiryCaptureStore: func fetchEnquiryData', error)
    }
  },
  async submitEnquiryCapture({ commit, state, dispatch }: any, { payload, dataStep }: any) {
    const arrayStepEnquiry = state.arrayStep
    const firstStepIndex = 0
    const enquiryID = getEnquiryID(payload?.path)
    const stepsData = state.stepsData && state.stepsData[payload.path] ? state.stepsData[payload.path] : {}
    const isFinalStep = payload?.step === arrayStepEnquiry[arrayStepEnquiry.length - 1]
    
    let params = {
      ...stepsData?.params,
      form_token: stepsData?.form_token,
      enquiry_id: enquiryID,
      step: payload?.step || null,
      [payload?.step]: payload[payload?.step],
      req_source: payload?.req_source || undefined,
      geo_loc: payload?.geo_loc || undefined,
      contest_gtm: payload?.contest_gtm || undefined,
      redirect_url: payload?.redirect_url || undefined,
      new_version: 1,
      is_final_step: isFinalStep ? 1 : undefined
    } as any
    if (!enquiryID || !params.form_token) {
      if(!state.recaptcha_token && state.recaptcha) {
        params['g-recaptcha-response'] =  await dispatch('getReCaptchaToken')
      }
      else params['g-recaptcha-response'] = state.recaptcha_token
    } else {
      delete params['g-recaptcha-response']
    }

    // async data form customise
    if (payload?.step === arrayStepEnquiry[firstStepIndex] && payload[EnquiryStepEnum.YourStyle]) {
      params = {
        ...params,
        [EnquiryStepEnum.YourStyle]: payload[EnquiryStepEnum.YourStyle],
      }
    }

    try {
      const { data: res } = await enquiryTravellerApi.submitMultiStepEnquiry(params)
      // console.log({params})
      if (res?.status === 200) {
        const next_step = arrayStepEnquiry[arrayStepEnquiry.indexOf(payload?.step) + 1]
        const form_token = res?.data?.form_token || null
        const enquiry_id = res?.data?.enquiry_id || null

        const stepsData = {
          enquiry_id: enquiry_id,
          [payload?.step]: { ...toRaw(dataStep) },
          form_token,
          current_step: payload?.step,
          next_step,
          path: payload?.path,
          params,
        }
    
        const sessionData = storeStepData(stepsData)
        commit('setStepsData', sessionData)
        if (res?.data?.enquiry_infos) {
          commit('setEnquiryCaptureData', res.data?.enquiry_infos)
        }
        if (res?.data?.authenticate) {
          commit('setEnquiryAuthenticate', res.data?.authenticate)
        }
      }
      return res
    } catch (error: any) {
      console.log(error)
      return {
        message: error?.message || '',
      }
    }
  },
  removeEnquiryData({ commit }: { commit: Commit }) {
    commit('setEnquiryCaptureData', null)
  },
  removeRouteStore({ commit }: { commit: Commit }, { path, clearSession = false }: { path: string; clearSession: boolean }) {
    commit('setEnquiryCaptureData', null)
    const enquiryMultiInfo = JSON.parse(localStorage.getItem(keyLocalStorage.enquiry)!)
    if (enquiryMultiInfo && path && enquiryMultiInfo[path]) {
      delete enquiryMultiInfo[path]
    }
    if (enquiryMultiInfo) {
      localStorage.setItem(keyLocalStorage.enquiry, JSON.stringify(enquiryMultiInfo))
      if (clearSession) sessionStorage.setItem(keyLocalStorage.enquiry, JSON.stringify(enquiryMultiInfo))
    }
    commit('setStepsData', null)
    commit('setEnquiryOptionData', {})
    commit('setGalleryData', { data: {}, listDestination: [] })
  },
  cachingStepEnquiry({ commit }: { commit: Commit }, payload: { step: EnquiryStepEnum; path: string }) {
    if (!payload.step) return
    const enquiryMultiInfo = JSON.parse(localStorage.getItem(keyLocalStorage.enquiry)!)
    if (enquiryMultiInfo && enquiryMultiInfo[payload.path]) {
      enquiryMultiInfo[payload.path.toString()].step = payload.step
      localStorage.setItem(keyLocalStorage.enquiry, JSON.stringify(enquiryMultiInfo))

      const session = JSON.parse(sessionStorage.getItem(keyLocalStorage.enquiry)!)
      sessionStorage.setItem(keyLocalStorage.enquiry, JSON.stringify({ ...session, [payload.path]: { ...enquiryMultiInfo[payload.path] } }))
      commit('setStepInPath', payload)
    }
  },
  async getReCaptchaToken({ state, commit }: any) {
    try {
      const token = await state.recaptcha.execute('submit')
      commit('setRecaptchaToken', token)
      return token
    } catch (e) {
      console.log('recaptcha error', e)
      throw new Error('recaptcha error: ', { cause: e })
    }
  },
}

const mapDataEnquiryMultiStep = (data: EnquiryMultiStepObj) => {
  const { enquiry_id, step, information, your_trip, how, your_style } = data
  let result = { enquiry_id, step, params: data } as any
  if (information) {
    const { phone, contact_name, check_newsletter, email } = information
    const phoneFormat = phone ? parsePhoneNumber(phone) : null
    let nationality = null
    if (phoneFormat && phoneFormat.countryCallingCode) {
      const ctr = countryList.find((item) => {
        return item.dial_code === phoneFormat.countryCallingCode
      })
      nationality = ctr ? ctr.code : null
    }
    result = {
      ...result,
      information: {
        email,
        check_newsletter: check_newsletter,
        contactName: contact_name,
        nationality,
        phone: phoneFormat?.nationalNumber || phone,
      },
    }
  }
  if (your_trip) {
    const { destinations_raw, exact_date, has_flight_ticket, length_of_trip, arrival_date, arrival_month, dates } = your_trip
    let dateSelected, ddMMMYYYY, mmmYYYY
    if (!!exact_date) {
      ddMMMYYYY = new Date(arrival_date)
      dateSelected = ddMMMYYYY?.getDate() + '-' + (ddMMMYYYY!.getMonth() + 1) + '-' + ddMMMYYYY?.getFullYear()
    } else {
      mmmYYYY = dates
      dateSelected = arrival_month
    }
    const lengthOfTrip = length_of_trip.replaceAll('days', '').replaceAll('day', '').trim()
    result = {
      ...result,
      your_trip: {
        destination: destinations_raw.map((v) => ({ ...v, id: v.city_id, country_code: v.country_id })),
        exactDate: !!exact_date,
        haveFlights: has_flight_ticket ? 'yes' : 'no',
        lengthOfTrip: lengthOfTrip !== '0' ? lengthOfTrip : null,
        ddMMMYYYY: ddMMMYYYY || null,
        mmmYYYY: mmmYYYY || null,
        dateSelected: dateSelected || null,
      },
    }
  }
  if (how) {
    const { accm_pref, ideal_room_config, pax } = how
    result = {
      ...result,
      how: {
        accm_pref: accm_pref ? accm_pref.split(',') : [],
        ideal_room_config,
        pax,
      },
    }
  }
  if (your_style) {
    const { suggestion } = your_style
    result = {
      ...result,
      your_style: {
        suggestion: suggestion || '',
      },
    }
  }
  return result
}

const state = () =>
  ({
    submitFrom: 'global', // 4 options: 'global', 'trip_plan', 'ld_profile', 'destination'
    hasEnquiryCapture: false,
    loading: false,
    enquiryCaptureData: null,
    userLoginData: {},
    enquiryOptionData: {},
    enquiryAuthenticate: {},
    recaptcha: '',
    recaptcha_token: '',
    galleryData: {
      data: {},
      listDestination: []
    },
    stepsData: {},
    arrayStep: ENQUIRY_STEP_BASE,
  } as EnquiryCaptureState)

export default {
  namespaced: true,
  mutations,
  getters,
  actions,
  state,
}
