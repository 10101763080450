import {useGetCookie} from "~/composables/common/useCustomCookie";

const mutations = {
    setCurrenciesList: (state, response) => {
        state.currenciesData = response
    },
    setCurrencySelected: (state: any, data: any) => {
        state.currencySelected = data
    },
    setCurrencyDropdownClick: (state, data) => {
        state.currencyChangeByUser = data
    },
    setTripBooked: (state, data) => {
        state.tripBooked = data
    },
    setLoaded: (state, data) => {
        state.loaded = data
    }
}

const getters = {
    getCurrencySelected: (state) => {
        // console.log('getCurrenciesData')
        return state.currencySelected
    },
    getCurrenciesList: (state) => {
        return state.currenciesData
    },
}

const state = () => ({
    currenciesData: null,
    currencySelected: {
        code: "",
        symbol: "",
        decimal: ".",
        label: "",
        rate: 1,
        separate: ""
    },
    currencyChangeByUser: 0,
    tripBooked: false,
    loaded: false
})

export default {
    namespaced: true,
    mutations,
    /*actions,*/
    getters,
    state,
}
