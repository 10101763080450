import homeApi from '~/composables/api/homeApi'

const GeneralInfo = {
    namespaced: true,
    state() {
        return {
            generalData: {
                windowHeight: 0, //process.client ? window.innerHeight : 0,
                windowWidth: 1220, //process.client ? window.innerWidth : 768,
                megaMenuInfor: {
                    status: false,
                    itemSelected: {},
                },
                tabIndexSelected: 0,
                megaMenuInforMB: {
                    status: false,
                    statusSubNav: false,
                    itemSelected: '',
                },
                specialDeals: [],
                appLoading: false,
                GTMTracking: {
                    ClientID: '',
                    GCLID: '',
                    TrafficSource: '',
                    TrafficMedium: '',
                    TrafficCampaign: '',
                    TrafficContent: '',
                    TrafficKeyword: '',
                    TrafficLandingPage: '',
                },
            },
            topMenuData: {},
            initChat: {
                status: false,
                token: '',
            },
            finishedChat: '',
            detectCountryLoaded: false,
            enquiryDetail: {
                showModalEnquiryDetail: false,
                enquiryDetailList: {}
            },
            detectCountryInfo: {},
            callDetect: false,
            enquirySubmitFormTA: false,
            carousel: {
                nextSlide: 0,
                preSlide: 0,
                listItems: []
            },
            clientRoute: ''
        }
    },
    mutations: {
        setWindowWidth(state) {
            state.generalData.windowWidth = process.client ? window.innerWidth : 768
            state.generalData.windowHeight = process.client ? window.innerHeight : 0
        },
        setWindowWidthSSR(state, data) {
            state.generalData.windowWidth = data
        },
        setMegaMenuStatus(state, data) {
            state.generalData.megaMenuInfor = data
        },
        setTabIndex(state, data) {
            state.generalData.tabIndexSelected = data
        },
        setMegaMenuStatusMB(state, data) {
            state.generalData.megaMenuInforMB = data
        },
        setTopMenu(state, data) {
            let new_list = []

            data.forEach((item) => {
                let name = item.title.toLowerCase()
                let obj = item
                obj.name = name
                new_list.push(obj)
            })

            state.topMenuData = new_list
        },
        setInitChat(state, response) {
            state.initChat = response
        },
        setFinishedChat(state, response) {
            state.finishedChat = response
        },
        setSpecialDeals(state, data) {
            state.generalData.specialDeals = data
        },
        setGTMTracking(state, data) {
            state.generalData.GTMTracking = data
        },
        setLoading(state, data) {
            state.generalData.appLoading = data
        },
        setDetectCountryLoaded(state, data) {
            state.detectCountryLoaded = data
        },
        setEnquiryDetail(state, data) {
            state.enquiryDetail = data
        },
        setDetectCountryInfo(state, data) {
            // console.log('[setDetectCountryInfo store]',data)
            state.detectCountryInfo = data
        },
        setCallDetect(state, data) {
            state.callDetect = data
        },
        setEnquirySubmitFormTA(state, data) {
            state.enquirySubmitFormTA = data
        },
        setCarouselNextSlide(state, data) {
            state.carousel.nextSlide = data
        },
        setCarouselPreSlide(state, data) {
            state.carousel.preSlide = data
        },
        setCarouselData(state, data) {
            state.carousel.listItems = data
        },
        setClientRoute(state: any, data: String) {
            state.clientRoute = data
        }
    },
    actions: {
        initChat(context, request) {
            context.commit('setInitChat', request)
        },
        finishedChat(context, request) {
            context.commit('setFinishedChat', request)
        },
        async fetchCarouselData(context) {
            const getApiSearchHome = (await homeApi.getHomeSearchSettings()).data.value

            const durations = getApiSearchHome?.data?.durations
            const travel_themes = getApiSearchHome?.data?.travel_themes
            const response = durations.concat(travel_themes)
            // console.log('[fetchCarouselData]',response)
            context.commit('setCarouselData', response)
            return response
        },
    },
}

export default GeneralInfo
