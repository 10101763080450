import {
    ENQUIRY_DELETED,
    LS_DELETED,
    DELETED
} from "~/constant/deletedTripPlan"
import { isEmpty } from "~/helper";

const actions = {
}

const mutations = {
    setDataTrip: (state, response) => {
        state.dataTrip = response;
    },
    setPriceDisplayAPI: (state, response) => {
        state.priceDisplayAPI = response
    },
    setPriceDisplay: (state, response) => {
        state.priceDisplay = response
    },
    setPriceDisplayGallery: (state, response) => {
        state.priceDisplayGallery = response
    },
    setAgentContact: (state, response) => {
        state.agentContactData = response
    },
    setProductData: (state, response) => {
        state.product = response
    },
    setOriginalRate: (state, response) => {
        state.originalRate = response
    },
    resetDataTrip: (state) => {
        state.dataTrip = {}
    },
    setOriginalPriceEdit: (state, response) => {
        state.originalPriceEdit = response
    },
    setTransactionData: (state, data) => {
        state.transactionData = data
    },
    setDataAffiliate: (state, response) => {
        state.dataAffiliate = response;
    },
    setGroupBookingData: (state, data) => {
        state.groupBookingData = { ...state.groupBookingData, ...data }
    },
    setEnquiryData: (state, data) => {
        state.dataEnquiry = data
    },
}

const getters = {
    tripPlanRouteChange: (state) => (state?.dataTrip?.is_deleted || state?.dataTrip?.is_deactivated || state?.dataTrip?.is_deleted_enquiry),
    deletedType: (state) => {
        return state?.dataTrip?.is_deactivated ? LS_DELETED : state?.dataTrip?.is_deleted_enquiry ? ENQUIRY_DELETED : state?.dataTrip?.is_deleted ? DELETED : ''
    },
    designer: (state) => {
        if (isEmpty(state?.dataTrip)) return null
        return state?.dataTrip.designer || null
    },
    isCompleted: (state) => state?.dataTrip?.is_completed,
    isZeroGroupTrip: (state) => state?.groupBookingData?.zeroGroupTrip && state?.dataTrip?.is_group_enquiry,
    transactionData: (state) => state?.transactionData,
    groupBookingData: (state) => state?.groupBookingData
}

const state = () => ({
    dataTrip: {
    },
    priceDisplayAPI: {},
    priceDisplay: {},
    priceDisplayGallery: {},
    agentContactData: {
        apiLoaded: false,
        data: {}
    },
    product: [],
    originalRate: {},
    originalPriceEdit: {
        // original_adjustment_amount: 0,
        // original_currency: "",
        // original_gross_price: 0,
        // original_net_price: 0
    },
    transactionData: {},
    dataAffiliate: {},
    groupBookingData: {
        confirmed_travellers: 0,
        confirmed_traveller_details: [{ first_name: '', last_name: '' }],
        zeroGroupTrip: false,
    },
    dataEnquiry: {},
})

export default {
    namespaced: true,
    mutations,
    actions,
    getters,
    state,
}
