import { travelExplorerURL, travelExplorerURLV3 } from './apiDomain'
import { travelExplorerHttp } from './http'

// let client = travelExplorerHttp(travelExplorerURL);
// let clientV3 = travelExplorerHttp(travelExplorerURLV3);

export default {
    getDetailProfile(id) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.get(`/user/details/${id}`)
    },
    getConsortiums() {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.get('/consortiums')
    },
    uploadAvatar(id, data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post(`/user/${id}/upload-avatar`, data)
    },
    updateProfile(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/account-settings', data)
    },
    updateProfileAgent(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/travel-agent/account-settings', data)
    },
    resetPassword(data) {
        const client = travelExplorerHttp(travelExplorerURL);
        return client.post('/user/reset-password', data)
    },
    sendNotify(data) {
        const clientV3 = travelExplorerHttp(travelExplorerURLV3);
        return clientV3.post('push-slack-notify', data)
    },
    sendTrackingEngagement(data) {
        const clientV3 = travelExplorerHttp(travelExplorerURLV3);
        return clientV3.post('tracking-engagement', data)
    }
}
