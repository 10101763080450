import getLocalDesignerApi from '~/composables/api/localDesignerApi'

const actions = {
    async getDesigner(context, slug) {
        const response = (await getLocalDesignerApi.getDesigner(slug)).data.value
        const data = response?.data
        context.commit('setDataDesigner', data)
    },
}

const mutations = {
    setDataListDesigners: (state, data) => {
        state.listDesigners = data
    },
    setDataDesigner: (state, data) => {
        state.designer = data
    },
    setGalleryLDData: (state, data) => {
        state.galleryLD = data
    },
}

const getters = {
    getListDesigner: (state) => state.listDesigners,
    getDesigner: (state) => state.designer,
}

const state = () => ({
    listDesigners: [],
    designer: {},
    galleryLD: {}
})

export default {
    namespaced: true,
    mutations,
    actions,
    getters,
    state,
}
