import { createStore } from "vuex"
import currenciesStore from './currencies'
import subcribeEmailStore from './subscribe-email'
import generalStore from './GeneralInfo'
import authStore from './Auth'
import tripPlanStore from './TripPlan'
import { tfChatStore } from 'tf-chat'
import galleryStore from './Gallery'
import searchStore from './Search'
import localDesignerStore from './localDesigner'
import profileStore from './Profile'
import heapStore from './heapStore'
import enquiryCapture from './EnquiryCapture'

export const useMainStore = createStore({
    modules: {
        authStore,
        currenciesStore,
        subcribeEmailStore,
        generalStore,
        tripPlanStore,
        tfChatStore,
        galleryStore,
        searchStore,
        localDesignerStore,
        profileStore,
        heapStore,
        enquiryCapture
    }
})
