import { default as About287T3gA0pDMeta } from "/build/pages/About.vue?macro=true";
import { default as AccountSettingCBtteZ2DzMMeta } from "/build/pages/AccountSetting.vue?macro=true";
import { default as BecomeLocalDesignerEbVX6GeLmFMeta } from "/build/pages/BecomeLocalDesigner.vue?macro=true";
import { default as BlogSubscribeMRjzpWwrO4Meta } from "/build/pages/BlogSubscribe.vue?macro=true";
import { default as BookingBalanceConfirmationZ00aF9SlLyMeta } from "/build/pages/BookingBalanceConfirmation.vue?macro=true";
import { default as BookingConfirmationeszH4OQZ4bMeta } from "/build/pages/BookingConfirmation.vue?macro=true";
import { default as BookingDetailsjgUdRPDlVuMeta } from "/build/pages/BookingDetails.vue?macro=true";
import { default as BookingListTravellertEvvtWbAMWMeta } from "/build/pages/BookingListTraveller.vue?macro=true";
import { default as ComingSoon1tvwljh2VoMeta } from "/build/pages/ComingSoon.vue?macro=true";
import { default as ContactUssGzmlqsqlxMeta } from "/build/pages/ContactUs.vue?macro=true";
import { default as CookieDeclaration8C2DlZGjZaMeta } from "/build/pages/CookieDeclaration.vue?macro=true";
import { default as CustomGroupTours1cayFDepYYMeta } from "/build/pages/CustomGroupTours.vue?macro=true";
import { default as DataProtectionNyEjTCLlhgMeta } from "/build/pages/DataProtection.vue?macro=true";
import { default as EmailConfirmuuPE9nSNSvMeta } from "/build/pages/EmailConfirm.vue?macro=true";
import { default as EnquiriesTravellerXcn2a7zeBoMeta } from "/build/pages/EnquiriesTraveller.vue?macro=true";
import { default as EnquiryConfirmationyR3AyoQYkUMeta } from "/build/pages/EnquiryConfirmation.vue?macro=true";
import { default as EnquiryProcessIntrepidTzgEwzB4fEMeta } from "/build/pages/EnquiryProcessIntrepid.vue?macro=true";
import { default as EnquiryProcessTraveller7mNOLLTVDAMeta } from "/build/pages/EnquiryProcessTraveller.vue?macro=true";
import { default as FeedbackjmdDpj97VJMeta } from "/build/pages/Feedback.vue?macro=true";
import { default as ForgotPassword0eInRAzztgMeta } from "/build/pages/ForgotPassword.vue?macro=true";
import { default as GallerymzRB0BglAVMeta } from "/build/pages/Gallery.vue?macro=true";
import { default as homejFt7DeMisCMeta } from "/build/pages/home.vue?macro=true";
import { default as HowItWorkss8DmGFJKPwMeta } from "/build/pages/HowItWorks.vue?macro=true";
import { default as LocalDesignerProfilew1mMjntkFrMeta } from "/build/pages/LocalDesignerProfile.vue?macro=true";
import { default as LocalDesignersGallery4Ug9hDFU0QMeta } from "/build/pages/LocalDesignersGallery.vue?macro=true";
import { default as LoginVs5e0mY9yVMeta } from "/build/pages/Login.vue?macro=true";
import { default as NewCheckOutDzlIdYa9iiMeta } from "/build/pages/NewCheckOut.vue?macro=true";
import { default as NotFoundQxVhsx7Z2EMeta } from "/build/pages/NotFound.vue?macro=true";
import { default as PressCoverPress0VYjl7NNwnMeta } from "/build/pages/PressCoverPress.vue?macro=true";
import { default as PrivacyPolicyQcmywaGBMvMeta } from "/build/pages/PrivacyPolicy.vue?macro=true";
import { default as ResetPassword2cAGikylEeMeta } from "/build/pages/ResetPassword.vue?macro=true";
import { default as SearchDownMLCPeWblg2Meta } from "/build/pages/SearchDown.vue?macro=true";
import { default as SearchResultH1cNnf5YbRMeta } from "/build/pages/SearchResult.vue?macro=true";
import { default as SignUp4LYRfobnaLMeta } from "/build/pages/SignUp.vue?macro=true";
import { default as SignUpAgentGBJLKybG9IMeta } from "/build/pages/SignUpAgent.vue?macro=true";
import { default as SignUpAgentInvitationfFZW2xRD6dMeta } from "/build/pages/SignUpAgentInvitation.vue?macro=true";
import { default as SignUpAgentSuccessKlsMKTiu8NMeta } from "/build/pages/SignUpAgentSuccess.vue?macro=true";
import { default as TermsOfUseqE0Waj4WpRMeta } from "/build/pages/TermsOfUse.vue?macro=true";
import { default as TermsOfUseTripPlanBuilder8SScmr5FtiMeta } from "/build/pages/TermsOfUseTripPlanBuilder.vue?macro=true";
import { default as TravelAgentBookingIncentiveZCjsaqgZ35Meta } from "/build/pages/TravelAgentBookingIncentive.vue?macro=true";
import { default as TravelGuideEnKlDwMfbTMeta } from "/build/pages/TravelGuide.vue?macro=true";
import { default as TravellerProtections1Y7qZqurLdMeta } from "/build/pages/TravellerProtections.vue?macro=true";
import { default as TripPlanDfnKyXcazYMeta } from "/build/pages/TripPlan.vue?macro=true";
import { default as TripPlanDetailDouKhtuH2MMeta } from "/build/pages/TripPlanDetail.vue?macro=true";
export default [
  {
    name: "About",
    path: "/About",
    component: () => import("/build/pages/About.vue").then(m => m.default || m)
  },
  {
    name: "AccountSetting",
    path: "/AccountSetting",
    component: () => import("/build/pages/AccountSetting.vue").then(m => m.default || m)
  },
  {
    name: "BecomeLocalDesigner",
    path: "/BecomeLocalDesigner",
    component: () => import("/build/pages/BecomeLocalDesigner.vue").then(m => m.default || m)
  },
  {
    name: "BlogSubscribe",
    path: "/BlogSubscribe",
    component: () => import("/build/pages/BlogSubscribe.vue").then(m => m.default || m)
  },
  {
    name: "BookingBalanceConfirmation",
    path: "/BookingBalanceConfirmation",
    component: () => import("/build/pages/BookingBalanceConfirmation.vue").then(m => m.default || m)
  },
  {
    name: "BookingConfirmation",
    path: "/BookingConfirmation",
    component: () => import("/build/pages/BookingConfirmation.vue").then(m => m.default || m)
  },
  {
    name: "BookingDetails",
    path: "/BookingDetails",
    component: () => import("/build/pages/BookingDetails.vue").then(m => m.default || m)
  },
  {
    name: "BookingListTraveller",
    path: "/BookingListTraveller",
    component: () => import("/build/pages/BookingListTraveller.vue").then(m => m.default || m)
  },
  {
    name: "ComingSoon",
    path: "/ComingSoon",
    component: () => import("/build/pages/ComingSoon.vue").then(m => m.default || m)
  },
  {
    name: "ContactUs",
    path: "/ContactUs",
    component: () => import("/build/pages/ContactUs.vue").then(m => m.default || m)
  },
  {
    name: "CookieDeclaration",
    path: "/CookieDeclaration",
    component: () => import("/build/pages/CookieDeclaration.vue").then(m => m.default || m)
  },
  {
    name: "CustomGroupTours",
    path: "/CustomGroupTours",
    component: () => import("/build/pages/CustomGroupTours.vue").then(m => m.default || m)
  },
  {
    name: "DataProtection",
    path: "/DataProtection",
    component: () => import("/build/pages/DataProtection.vue").then(m => m.default || m)
  },
  {
    name: "EmailConfirm",
    path: "/EmailConfirm",
    component: () => import("/build/pages/EmailConfirm.vue").then(m => m.default || m)
  },
  {
    name: "EnquiriesTraveller",
    path: "/EnquiriesTraveller",
    component: () => import("/build/pages/EnquiriesTraveller.vue").then(m => m.default || m)
  },
  {
    name: "EnquiryConfirmation",
    path: "/EnquiryConfirmation",
    component: () => import("/build/pages/EnquiryConfirmation.vue").then(m => m.default || m)
  },
  {
    name: "EnquiryProcessIntrepid",
    path: "/EnquiryProcessIntrepid",
    component: () => import("/build/pages/EnquiryProcessIntrepid.vue").then(m => m.default || m)
  },
  {
    name: "EnquiryProcessTraveller",
    path: "/EnquiryProcessTraveller",
    component: () => import("/build/pages/EnquiryProcessTraveller.vue").then(m => m.default || m)
  },
  {
    name: "Feedback",
    path: "/Feedback",
    component: () => import("/build/pages/Feedback.vue").then(m => m.default || m)
  },
  {
    name: "ForgotPassword",
    path: "/ForgotPassword",
    component: () => import("/build/pages/ForgotPassword.vue").then(m => m.default || m)
  },
  {
    name: "Gallery",
    path: "/Gallery",
    component: () => import("/build/pages/Gallery.vue").then(m => m.default || m)
  },
  {
    name: "home",
    path: "/home",
    component: () => import("/build/pages/home.vue").then(m => m.default || m)
  },
  {
    name: "HowItWorks",
    path: "/HowItWorks",
    component: () => import("/build/pages/HowItWorks.vue").then(m => m.default || m)
  },
  {
    name: "LocalDesignerProfile",
    path: "/LocalDesignerProfile",
    component: () => import("/build/pages/LocalDesignerProfile.vue").then(m => m.default || m)
  },
  {
    name: "LocalDesignersGallery",
    path: "/LocalDesignersGallery",
    component: () => import("/build/pages/LocalDesignersGallery.vue").then(m => m.default || m)
  },
  {
    name: "Login",
    path: "/Login",
    component: () => import("/build/pages/Login.vue").then(m => m.default || m)
  },
  {
    name: "NewCheckOut",
    path: "/NewCheckOut",
    component: () => import("/build/pages/NewCheckOut.vue").then(m => m.default || m)
  },
  {
    name: "NotFound",
    path: "/NotFound",
    component: () => import("/build/pages/NotFound.vue").then(m => m.default || m)
  },
  {
    name: "PressCoverPress",
    path: "/PressCoverPress",
    component: () => import("/build/pages/PressCoverPress.vue").then(m => m.default || m)
  },
  {
    name: "PrivacyPolicy",
    path: "/PrivacyPolicy",
    component: () => import("/build/pages/PrivacyPolicy.vue").then(m => m.default || m)
  },
  {
    name: "ResetPassword",
    path: "/ResetPassword",
    component: () => import("/build/pages/ResetPassword.vue").then(m => m.default || m)
  },
  {
    name: "SearchDown",
    path: "/SearchDown",
    component: () => import("/build/pages/SearchDown.vue").then(m => m.default || m)
  },
  {
    name: "SearchResult",
    path: "/SearchResult",
    component: () => import("/build/pages/SearchResult.vue").then(m => m.default || m)
  },
  {
    name: "SignUp",
    path: "/SignUp",
    component: () => import("/build/pages/SignUp.vue").then(m => m.default || m)
  },
  {
    name: "SignUpAgent",
    path: "/SignUpAgent",
    component: () => import("/build/pages/SignUpAgent.vue").then(m => m.default || m)
  },
  {
    name: "SignUpAgentInvitation",
    path: "/SignUpAgentInvitation",
    component: () => import("/build/pages/SignUpAgentInvitation.vue").then(m => m.default || m)
  },
  {
    name: "SignUpAgentSuccess",
    path: "/SignUpAgentSuccess",
    component: () => import("/build/pages/SignUpAgentSuccess.vue").then(m => m.default || m)
  },
  {
    name: "TermsOfUse",
    path: "/TermsOfUse",
    component: () => import("/build/pages/TermsOfUse.vue").then(m => m.default || m)
  },
  {
    name: "TermsOfUseTripPlanBuilder",
    path: "/TermsOfUseTripPlanBuilder",
    component: () => import("/build/pages/TermsOfUseTripPlanBuilder.vue").then(m => m.default || m)
  },
  {
    name: "TravelAgentBookingIncentive",
    path: "/TravelAgentBookingIncentive",
    component: () => import("/build/pages/TravelAgentBookingIncentive.vue").then(m => m.default || m)
  },
  {
    name: "TravelGuide",
    path: "/TravelGuide",
    component: () => import("/build/pages/TravelGuide.vue").then(m => m.default || m)
  },
  {
    name: "TravellerProtections",
    path: "/TravellerProtections",
    component: () => import("/build/pages/TravellerProtections.vue").then(m => m.default || m)
  },
  {
    name: "TripPlan",
    path: "/TripPlan",
    component: () => import("/build/pages/TripPlan.vue").then(m => m.default || m)
  },
  {
    name: "TripPlanDetail",
    path: "/TripPlanDetail",
    component: () => import("/build/pages/TripPlanDetail.vue").then(m => m.default || m)
  }
]