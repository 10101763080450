<script setup>
  import {useRouter } from "vue-router";
  import { computed } from 'vue'
  import { useStore } from 'vuex'
  import { DESKTOP } from '~/constant/common'

  const props = defineProps({
    error: Object
  })

  const router = useRouter()
  const store = useStore()
  const layout = 'no-footer-layout'

  const windowWidth = computed(() => store.state.generalStore.generalData.windowWidth)

  const backToHome = () => clearError({ redirect: '/' })

</script>

<template>
  <div>
    <header class="top-0 z-999 bg-white sticky" id="header">
      <div id="main-header">
        <div class="flex-wrapper pl-40 pr-40 relative mb-pl-20 mb-pr-20"><div class="hp-100 wp-100 absolute top-0 left-0"></div>
          <div class="left relative">
            <div class="left-logo">
              <a href="/" class="" title="Go to Homepage" aria-label="Go to Homepage">
                <!--            <button class='button button-blue' @click="backToHome">-->
                <img src="https://s3-cdn.designerjourneys.com/static/images/general/dj-logo-b.svg" width="244" height="30" alt="Logo - Designer Journeys">
                <!--            </button>-->
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main>
      <div class='notfound-page flex justify-center' :style="[windowWidth > DESKTOP ? 'min-height: calc(100vh - 131px)' : 'min-height: calc(100vh - 101px)']" >
        <div>
          <div class='pa-60 mb-pa-30 bg-white  tc maxw-360 mb-w-300 br-16 sd-1 mt-120 mb-120' v-if="error.statusCode == 404">
            <h2 class='mb-30 fs-36 mb-fs-28 fw-900'>Are you lost?</h2>
            <p class='mb-30 fs-18 lh-22'>
              You’ve travelled too far!
            </p>
            <p class='mb-30 fs-18 lh-22'>
              We are sorry, but the page you are looking for can’t be found.
            </p>
            <div>
              <button class='button button-blue' @click="backToHome">Back to Homepage</button>
            </div>
          </div>
          <div class='pa-60 mb-pa-30 bg-white  tc maxw-360 mb-w-300 br-16 sd-1 mt-120 mb-120' v-else>
            <h2 class='mb-30 fs-36 mb-fs-28 fw-900'>Oops, something went wrong!</h2>
            <p class='mb-30 fs-18 lh-22'>
              It looks like something broke.
              <br/>
              Sorry about that.
            </p>
            <p class='mb-30 fs-18 lh-22'>
              Error: {{ error.message }}
            </p>
            <div>
              <button class='button button-blue' @click="backToHome">Back to Homepage</button>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<style scoped lang="scss">
#main-header {
  border-bottom: 1px solid #F1F1F1;
  .flex-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
  }
}

  .notfound-page {
    background-image: url("https://s3-cdn.designerjourneys.com/static/images/notfound/bg-notfound.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    width: 100%;
    @include phone {
      background-image: url("https://s3-cdn.designerjourneys.com/static/images/notfound/mb-bg-notfound.jpg");
    }
  }
</style>
