import { EnquiryStepEnum, keyLocalStorage } from '~/enum/enquiry'

export const storeStepData = (data: any, isAllStep = false) => {
  if (!data.current_step && !isAllStep) return
  const now = new Date()
  let info = {
    timestamp: now.setDate(now.getDate() + 90), //90 days
    user: isAllStep
      ? {
          ...data.enquiry[EnquiryStepEnum.Information],
        }
      : {
          [EnquiryStepEnum.Information]: data[EnquiryStepEnum.Information],
        },
    [data.path!]: isAllStep
      ? {
          ...data.enquiry,
        }
      : {
          enquiry_id: data.enquiry_id || getEnquiryID(data?.path),
          [data?.current_step]: data[data?.current_step],
          step: data?.next_step,
          form_token: data?.form_token,
          params: {...data.params, form_token: data?.form_token},
        },
  }

  let enquirySS = null
  const enquiryOldSS = getDataEnquiry()
  if(enquiryOldSS) {
    enquirySS = {...enquiryOldSS[data.path!], ...info[data.path!]}
    enquirySS = {...enquiryOldSS, ...{[data.path!]: enquirySS} }
    if(data?.current_step === EnquiryStepEnum.Information) {
      enquirySS = {...enquirySS, user: info.user }
    }
  }
  else {
    enquirySS = { ...info }
  }
  if(enquirySS.timestamp === undefined) {
    enquirySS.timestamp = now.setDate(now.getDate() + 90)
  }
  // console.log('[storeStepData SS]',enquirySS)
  localStorage.setItem(keyLocalStorage.enquiry, JSON.stringify(enquirySS))
  sessionStorage.setItem(keyLocalStorage.enquiry, JSON.stringify(enquirySS))
  return enquirySS
}

export const checkExpiredLocalStorage = () => {
  const localJSON = localStorage.getItem(keyLocalStorage.enquiry)
  const now = new Date()
  const local = localJSON ? JSON.parse(localJSON) : null
  if (local && local?.timestamp && local?.timestamp < now) {
    localStorage.removeItem(keyLocalStorage.enquiry)
    sessionStorage.removeItem(keyLocalStorage.enquiry)
  }
}

export const getDataEnquiry = () => {
  let enquiryOldSS = JSON.parse(localStorage.getItem(keyLocalStorage.enquiry)!)
  if (!enquiryOldSS) enquiryOldSS = JSON.parse(sessionStorage.getItem(keyLocalStorage.enquiry)!)
  return enquiryOldSS
}

export const getEnquiryID = (path: string) => {
  const dataSession = getDataEnquiry()
  if (dataSession && dataSession[path]) {
    return dataSession[path].enquiry_id || null
  }
  return null
}
