import {
  AU_DEFAULT_PHONE,
  TA_DEFAULT_PHONE,
  TRAVELLER_DEFAULT_PHONE,
  UK_DEFAULT_PHONE,
  US_DEFAULT_PHONE
} from '~/constant/common'

export function CurrencyToUSD(rate, volume, isRound = true) {
  if (typeof volume !== 'number' || volume === 0) {
    return volume
  }

  let exchange_value = parseFloat(volume) / rate

  if (isRound) {
    exchange_value = Math.ceil(exchange_value)
  }

  return exchange_value
}

export function USDToCurrency({ rate, volume, currency, isRound = true, hasFee = false }) {
  if (typeof volume !== 'number' || volume === 0 || currency === 'USD') {
    return volume
  }

  // priceOrginal(VND) => priceUSD => priceUserSelected(CAD)
  // priceUSD = priceOrginal(VND) / rate(VND)
  // priceUserSelected(CAD) = priceUSD * rate(CAD)
  // priceFinal = priceUserSelected(CAD)*2% + priceUserSelected(CAD)

  let exchange_value = 0

  // FEE 2%
  const fee = 1.02

  if(hasFee) exchange_value = parseFloat(volume) * rate * fee
  else exchange_value = parseFloat(volume) * rate

  if (isRound) {
    exchange_value = Math.ceil(exchange_value)
  }

  return exchange_value
}

export function findExchangeRate(currenciesData, currency_code) {
  const rate = currenciesData.find(
    ({ code }) => code === currency_code
  )
  return rate
}

export function exchangeRate(priceOriginal, rateOriginal, rateExchange, hasFee = false, isRound = true, isFormat = true) {
  // FEE 2%
  const fee = 1.02

  let exchange_value = ((priceOriginal / rateOriginal) * rateExchange)

  if(hasFee) exchange_value = exchange_value * fee

  if(isRound) exchange_value = Math.ceil(exchange_value)

  // let priceFinal = exchange_value.toFixed(2)
  // let priceFinal = (Math.round(exchange_value * 100) / 100).toFixed(2)

  if(isFormat && !isRound) return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(exchange_value))

  if(isFormat && isRound) return new Intl.NumberFormat().format(parseFloat(exchange_value))

  return exchange_value
}

export function markupPriceCalculation(netPriceOriginal, rateOriginal, originalAdjustmentAmount, rateExchange, isRound = false, isFormat = true) {
  // FEE 2%
  const fee = 1.02

  let markup_value = ((netPriceOriginal * fee + originalAdjustmentAmount) * rateExchange) / rateOriginal

  if(isFormat && !isRound) return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(parseFloat(markup_value))

  if(isFormat && isRound) return new Intl.NumberFormat().format(parseFloat(markup_value))

  return markup_value
}

export function formatCurrency(volume, currency, volumeOnly = false) {
  return volumeOnly
    ? `${new Intl.NumberFormat().format(Math.ceil(volume))}`
    : `${currency} ${new Intl.NumberFormat().format(Math.ceil(volume))}`
}

export function formatCurrencyNoRound(volume) {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(volume)
}

export function formatCurrencyRound(volume) {
  return new Intl.NumberFormat().format(Math.ceil(volume))
}

export function formatDisplayPrice(volume, round = false) {
  if(round) return new Intl.NumberFormat().format(Math.ceil(volume))
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(volume)
}

export function getDepositFlat(currencyUser, priceExchanged, marketFee) {
  if(currencyUser === 'JPY') {
    if(parseInt(priceExchanged) <= 100000) {
      if(marketFee < 5000) return marketFee
      if(marketFee >= 5000) return 5000
    }
    return 10000
  }
  else {
    if(parseInt(priceExchanged) <= 1000) {
      if(marketFee < 50) return marketFee
      if(marketFee >= 50) return 50
    }
    return 100
  }
}

export function formatDate(date, format = 'd m Y') {
  const d = new Date(date)
  const m = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ]
  if (format === 'm d Y') {
    return m[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
  }
  if (format === 'dd/mm/yyyy') {
    return d.getDate() + '/' + (d.getMonth() + 1) + '/' + d.getFullYear()
  }
  if (format) {
    return d.getDate() + ' ' + m[d.getMonth()] + ' ' + d.getFullYear()
  }
  return d.getFullYear() + '-' + (d.getMonth() + 1) + '-' + d.getDate()
}

export function formatName(person) {
  return person.first_name + '-' + person.last_name.substr(0, 1)
}

export function capitalize(string) {
  return string.replace(/-([a-z])/g, (_, char) => ' ' + char.toUpperCase()).replace(/^./, (x) => x.toUpperCase())
}

export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    !value ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

export function cloneDeep(entity, cache = new WeakMap()) {
  const referenceTypes = ['Array', 'Object', 'Map', 'Set', 'WeakMap', 'WeakSet']
  const entityType = Object.prototype.toString.call(entity)
  if (!new RegExp(referenceTypes.join('|')).test(entityType)) return entity
  if (cache.has(entity)) {
    return cache.get(entity)
  }
  const c = new entity.constructor()

  if (entity instanceof Map || entity instanceof WeakMap) {
    entity.forEach((value, key) => c.set(cloneDeep(key), cloneDeep(value)))
  }
  if (entity instanceof Set || entity instanceof WeakSet) {
    entity.forEach((value) => c.add(cloneDeep(value)))
  }
  cache.set(entity, c)
  return Object.assign(
    c,
    ...Object.keys(entity).map((prop) => ({
      [prop]: cloneDeep(entity[prop], cache),
    })),
  )
}

export function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx)
}

export function getLoopMonth(number) {
  let now = new Date()
  let month = now.getMonth()
  let year = now.getFullYear()

  const names = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']

  const res = []
  for (let i = 0; i < number; ++i) {
    res.push(names[month] + ' ' + year)
    if (++month === 12) {
      month = 0
      ++year
    }
  }
  return res
}

export function publicDomain() {
  if (!process.client) {
    return 'traveller';
  }

  //const config = useRuntimeConfig()
  const VUE_APP_PUBLIC_TYPE = useAppConfig().publicType
  if (
    VUE_APP_PUBLIC_TYPE === 'agent' ||
    window.APP_CODE === 'agent'
  )
    return 'agent'
  if (
    VUE_APP_PUBLIC_TYPE === 'preview' ||
    window.APP_CODE === 'preview'
  )
    return 'preview'
  if (
    VUE_APP_PUBLIC_TYPE === 'agentpreview' ||
    window.APP_CODE === 'agentpreview'
  )
    return 'agentpreview'
  return 'traveller'
}

export function plural(
  number,
  singleText = '',
  removeOnZero = false,
  pluralText = '',
  joinChar = ' ',
) {
  if (isNaN(number)) {
    return ''
  }
  if (removeOnZero && number == 0) {
    return ''
  }
  if (!pluralText) {
    pluralText = singleText + 's'
  }
  return [number, number != 1 ? pluralText : singleText].join(joinChar)
}

export function getMonthNumberFromText(monthYear, splitChar = ' ') {
  // example: April 2022
  let monthTextArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  let mY = monthYear.split(splitChar)
  let month = mY[0],
    year = Number(mY[1])
  return [monthTextArr.indexOf(month) + 1, year].join('-')
}

export function generateArrayOfYears() {
  let max = new Date().getFullYear()
  let min = max + 10
  let years = []

  for (let i = max; i <= min; i++) {
    years.push(i)
  }
  return years
}

export function pluralize({ count, noun, suffix = 's', full = true }) {
  return full ? `${count} ${noun}${count !== 1 ? suffix : ''}` : `${noun}${count !== 1 ? suffix : ''}`
}

export function realParseFloat(s) {
  s = s.toString().replace(/[^\d,.-]/g, '') // strip everything except numbers, dots, commas and negative sign
  if (
    navigator.language.substring(0, 2) !== 'de' &&
    /^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:\.\d+)?$/.test(s)
  ) {
    // if not in German locale and matches #,###.######
    s = s.toString().replace(/,/g, '') // strip out commas
    return parseFloat(s) // convert to number
  } else if (/^-?(?:\d+|\d{1,3}(?:\.\d{3})+)(?:,\d+)?$/.test(s)) {
    // either in German locale or not match #,###.###### and now matches #.###,########
    s = s.toString().replace(/\./g, '') // strip out dots
    s = s.toString().replace(/,/g, '.') // replace comma with dot
    return parseFloat(s)
  } // try #,###.###### anyway
  else {
    s = s.toString().replace(/,/g, '') // strip out commas
    return parseFloat(s) // convert to number
  }
}

export function numberWithCommas(number) {
  const usFormatter = new Intl.NumberFormat('en-US')
  return usFormatter.format(number)
}

export function scrollToHash(hash, delay = 10) {
  const id = hash.replace(/^#/, '')
  const headerHeight = document.getElementById('header').clientHeight

  setTimeout(() => {
    const element = document.getElementById(id)
    if (element) {
      let elementPosition = element.getBoundingClientRect().top
      let offsetPosition = elementPosition - headerHeight
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      })
    }
  }, delay)
}

export function nl2br(str) {
  return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
}

export function hasKey(object, key) {
  return object ? hasOwnProperty.call(object, key) : false
}

import countryList from '../components/ui/country-selectbox/country.json';
export function getCountryPhone(country_code) {
  let country = countryList.find(item => {
    return item.code === country_code
  })
  return country ? '+' + country.dial_code : ''
}

export function getCountryName(country_code) {
  let country = countryList.find(item => {
    return item.code === country_code
  })
  return country ? country.name : ''
}

export const MathUtils = {
  // linear interpolation
  lerp: (a, b, n) => (1 - n) * a + n * b,
}

import timezoneList from '~/components/pages/AccountSetting/countries_with_timeZones.json'
export function timezone() {
  const timeZones = []
  timezoneList.forEach((element) => {
    element.WindowsTimeZones.forEach((item, index) => {
      const nameTimeZone = item.Name
      const found = timeZones.find(
        (element) => element.name == nameTimeZone,
      )
      if (!found) {
        const tz = element.TimeZones[index]

        timeZones.push({
          value: tz,
          name: item.Name,
        })
      }
    })
  })
  return timeZones.sort((a, b) => a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1)
}

export function groupContinent(listOriginal) {
  let listFinal = []
  let dataSort = [...listOriginal]

  dataSort.sort((a, b) =>
    a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1,
  )

  let grouped = dataSort.reduce(function (acc, obj) {
    let key = obj.continent
    if (key) {
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
    }
    return acc
  }, {})

  const entries = Object.entries(grouped)
  entries.forEach(([key, value]) => {
    let obj = {
      title: capitalize(key),
      items: value,
    }
    listFinal.push(obj)
  })
  listFinal.sort((a, b) =>
    a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1,
  )

  return listFinal
}

export const saveSmallAvatar = (src) => {
  let el = document.getElementById('user_avatar');
      if (el) {
        el.src = src;
      } else {
        document.getElementById('span_name').classList.add('dn')
        let img = document.createElement('img')
        img.setAttribute('id', 'user_avatar')
        img.setAttribute('src', src)
        img.setAttribute('class', 'img-cover')
        let circle = document.getElementById('circle_avatar')
        circle.append(img)
      }
}

const isObject = (object) => {
  return object != null && typeof object === "object";
};

export const isDeepEqual = (object1, object2) => {

  const objKeys1 = Object.keys(object1);
  const objKeys2 = Object.keys(object2);

  if (objKeys1.length !== objKeys2.length) return false;

  for (var key of objKeys1) {
    const value1 = object1[key];
    const value2 = object2[key];

    const isObjects = isObject(value1) && isObject(value2);

    if ((isObjects && !isDeepEqual(value1, value2)) ||
      (!isObjects && value1 !== value2)
    ) {
      return false;
    }
  }
  return true;
};

export const debounce = (fn, delay = 0, immediate = false) => {
  let timeout
  return (...args) => {
    if (immediate && !timeout) fn(...args)
    clearTimeout(timeout)

    timeout = setTimeout(() => {
      fn(...args)
    }, delay)
  }
}

export const isEmptyObj = (obj) => {
  return Object.keys(obj).length === 0
}

export const daysBetween = (date1, date2, exactNumber = false) => {
  const one_day = 1000 * 60 * 60 * 24
  const diff = Math.abs(date2 - date1)
  if (exactNumber) {
    return (diff * 1.0 / one_day)
  }
  return Math.round(diff / one_day)
}

export const getNameOfMonth = (date) => {
  const monthTextArr = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  return monthTextArr[date.getMonth()]
}

export const formatHTML = (text, numCharacter) => {
  return text?.replaceAll(/<\/?[^>]+(>|$)/gi, "")?.replaceAll(`\r\n`," ")?.slice(0, numCharacter-1)
}

/**
 * This function is used to get the operation number for the phone number
 *
 * @param  {string} countryCode   Country code of user
 * @param  {string} continentCode Continent code of user
 * @return {string}               Operation Number TC
 */
export const getOperationNumber = (countryCode, continentCode) => {
  const domain = publicDomain();
  let phone = domain === 'traveller' ? TRAVELLER_DEFAULT_PHONE : TA_DEFAULT_PHONE

  if(countryCode === 'AU') {
    phone = AU_DEFAULT_PHONE
  } else if(continentCode === 'NA' || continentCode === 'SA') {
    phone = US_DEFAULT_PHONE
  } else if(continentCode === 'EU' || continentCode === 'AF') {
    phone = UK_DEFAULT_PHONE
  }

  return phone
}

export const formatIntergerDigits = (number) => {
  return new Intl.NumberFormat('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false
  }).format(number) }

export const objectsDifferentBetweenArrays = (arr1, arr2) => {
  const result = arr1.filter((item) => !arr2.some((obj) => obj.id === item.id))
  return result
}

export const getRandomArbitrary = (min, max) => {
  return Math.random() * (max - min) + min
}

export const getLengthOfAllItemObject = (obj) => {
  let totalLength = 0
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      totalLength += obj[key].length
    }
  }
  return totalLength
}

export const createUUID = () => {
  let uuid = localStorage.getItem('dj_uuid')
  if(!uuid) {
    if (typeof crypto.randomUUID === "function") uuid = crypto.randomUUID()
    else uuid = new Date().getTime()
    localStorage.setItem('dj_uuid', `${uuid}`)
  }
  return uuid
}

export const assetCDN = () => {
  const VUE_APP_ENV = useAppConfig().appEnvType
  const VUE_APP_STATIC_CDN = useAppConfig().staticCDN
  return VUE_APP_ENV === 'local' ? '/assets' : `${VUE_APP_STATIC_CDN}/static/public`
}

export const addDays = (date, days) => {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
}
