import revive_payload_client_4sVQNw7RlN from "/build/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/build/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/build/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/build/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/build/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_UciE0i6zes from "/build/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/build/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/build/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_j7wLaRg9eH from "/build/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import plugin_t2GMTTFnMT from "/build/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import _00_axios_Oewm7ScXn7 from "/build/plugins/00.axios.ts";
import _01_vuex_UHKIi9iGLx from "/build/plugins/01.vuex.ts";
import _02_logger_client_gPqPRWc1pe from "/build/plugins/02.logger.client.ts";
import _03_error_handler_8dbDne5hkM from "/build/plugins/03.error-handler.ts";
import _04_detect_currency_WdyC7cspy4 from "/build/plugins/04.detect-currency.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  chunk_reload_client_UciE0i6zes,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_client_j7wLaRg9eH,
  plugin_t2GMTTFnMT,
  _00_axios_Oewm7ScXn7,
  _01_vuex_UHKIi9iGLx,
  _02_logger_client_gPqPRWc1pe,
  _03_error_handler_8dbDne5hkM,
  _04_detect_currency_WdyC7cspy4
]