import { debounceHeapTracking } from '~/mixins/heap'
const state = () => {
    return {
        source_widget: '',
        LDProfile: {
            source_widget: "external_link"
        },
        Gallery: {
            source_widget: "external_link"
        },
        chatWindow: {
            data: {
                local_designer_chat_status: '',
                last_seen_at: '',
                enquiry: '',
                local_designer: '',
                trip_plan_url: '',
                destination: '',
                type: '',
                enquiry_status: '',
                number_of_unread_message: '',
            },
            shouldCallHeap: false
        }
    }
}

const mutations = {
    setLDProfileSource: (state, response) => {
        state.LDProfile = { source_widget: response }
    },
    setGallerySource: (state, response) => {
        state.Gallery = { source_widget: response }
    },
    setChatWindowHeapTrackStatus: (state, response) => {
        state.chatWindow.shouldCallHeap = response
    },
    setChatWindowHeapData: (state, response) => {
        state.chatWindow.data = { ...state.chatWindow.data, ...response }
    }
}

const getters = {
    getLDProfileHeap: (state) => state.LDProfile,
    getGalleryHeap: (state) => state.Gallery,
    getChatWindowHeap: (state) => state.chatWindow
}

const actions = {
    changeLDProfileHeap(context, data) {
        context.commit('setLDProfileSource', data)
    },
    changeGalleryHeap(context, data) {
        context.commit('setGallerySource', data)
    },
    changeChatWindowHeapData({ commit }, data) {
        commit('setChatWindowHeapData', data)
    },
    setTrackChatWindow({ commit }, data) {
        commit('setChatWindowHeapTrackStatus', data)
    },
    heapTrackingChatWindow({ state }, data) {
        if (state.chatWindow.shouldCallHeap) {
            debounceHeapTracking('View Chat Window', { ...state.chatWindow.data, ...data })
        }
        return
    },
    heapTrackingMinimizeChat() {
        debounceHeapTracking('Minimize Chat Window')
    },
    heapTrackingMakePhoneCall(context, source_widget) {
        debounceHeapTracking('Make Phone Call', { source_widget })
    },
    heapTrackingViewListChat(context, data) {
        debounceHeapTracking('View All Chats', data)
    },
    heapTrackingErrorCaptured(context, data) {
        debounceHeapTracking('Error Captured', data)
    }
}

export default {
    namespaced: true,
    mutations,
    actions,
    state,
    getters
}
