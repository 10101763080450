import { contentServiceURL, travelExplorerURLV3 } from './apiDomain'
// import {travelExplorerDomain} from './apiDomain'
import { createHttp, travelExplorerHttp } from './http'
import {useFetchPublicApi} from "~/composables/fetch/fetchComposables";

// let client = createHttp(contentServiceURL);
// let clientTE = createHttp(travelExplorerDomain);

export default {
    getListDesigners() {
        const client = createHttp(contentServiceURL);
        return client.get('/designers')
    },
    getDesigner(slug, params) {
        // let cache = (window.__INITIAL_DATA__ && typeof window.__INITIAL_DATA__[slug] != 'undefined') ? window.__INITIAL_DATA__[slug] : null
        // if (cache && cache !== 'null' && cache.id) {
            //console.log(slug, cache)
        //     return cache
        // }

        // const client = createHttp(contentServiceURL);
        return useFetchPublicApi.get(`${contentServiceURL}/designers/${slug}`, params, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    getTravellerReviews(payload) {
        // const client = createHttp(contentServiceURL);
        return useFetchPublicApi.get(`${contentServiceURL}/traveller-reviews`, payload, {
            baseURL: process.server ? useRuntimeConfig().localProductContentUrl : useAppConfig().baseApiUrl
        })
    },
    getEnquiryDesigners(slug) {
        const client = createHttp(contentServiceURL);
        return client.get(`/designers/${slug}/enquiry-options`)
    },
    getLocalDesignersGallery(params) {
        const client = createHttp(contentServiceURL);
        return client.get('/designers/trips', { params: params })
    },
    getLocalDesignersAssigned(params) {
        const client = createHttp(contentServiceURL);
        return client.get('/designers', { params: params })
    },
    getTravelConcierges(params) {
        const clientTEV3 = travelExplorerHttp(travelExplorerURLV3)
        return clientTEV3.get('/travel-concierges', { params: params })
    }
}
