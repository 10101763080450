import subscribeEmailApi from '~/composables/api/subscribeEmailApi'

const actions = {
    async subscribeEmail(context, request) {
        const response = await subscribeEmailApi.subscribe(request)
        context.commit('setSubscribeEmail', response.data)
        return response.data
    },
}

const mutations = {
    setSubscribeEmail: (state, response) => {
        state.subscribeData = response
    },
}

const state = {
    subscribeData: {}
}

export default {
    namespaced: true,
    mutations,
    actions,
    state,
}
