
import accountApi from '~/composables/api/accountApi'

const actions = {
    async getProfile(context, id) {
        const response = await accountApi.getDetailProfile(id)
        context.commit('setProfile', response.data.data)
        return response.data
    },
    async setStoreName(context, data) {
        context.commit('setStoreName', data)
    }
}

const mutations = {
    setProfile: (state, data) => {
        state.profile = data
    },
    setStoreName: (state, data) => {
        state.profile.store.store_name = data
    }
}

const getters = {
    getProfile: (state) => state.profile,
}

const state = () => ({
    profile: {},
})

export default {
    namespaced: true,
    mutations,
    actions,
    getters,
    state,
}
